import Vue from "vue";
import VueRouter from "vue-router";
import axios from '@/config';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "about" */ "../views/layout"),
        redirect: "/home",
        children: [
            {
                path: "home",
                component: () => import(/* webpackChunkName: "slowlog" */ "../views/Home"),
            }, {
                path: "sitepasswd",
                component: () => import(/* webpackChunkName: "slowlog" */ "../views/SitePassword"),
            }, {
                path: "task",
                component: () => import(/* webpackChunkName: "slowlog" */ "../views/Task"),
            }, {
                path: "chat",
                component: () => import(/* webpackChunkName: "slowlog" */ "../views/chatWithGPT"),
            }, {
                path: "test",
                component: () => import(/* webpackChunkName: "slowlog" */ "../views/test"),
            }, {
                path: "modifypassword",
                component: () => import(/* webpackChunkName: "slowlog" */ "../views/ModifyPassword"),
            }
        ],


    },
    {
        path: "/login",
        component: () => import("../views/logIn"),
    },
    // {
    //   path: "/redirectlogin",
    //   redirect: 'http://172.19.6.226:8080/auth/realms/demo/protocol/openid-connect/auth?client_id=vue-demo&redirect_uri=http%3A%2F%2F127.0.0.1%3A8081%2F&state=bb&response_mode=fragment&response_type=code&scope=openid&nonce=aa',
    // },
    {
        path: "*",
        name: "no name",
        component: () => import("../views/404/404")
    }
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
        const title1 = localStorage.getItem('userName')
        document.title = title1 ? title1 : '呵呵哒，快登录^_^'
        next()
    }
)
// router.beforeEach((to, from, next) => {
//     // 假设你有一个名为 isAuthenticated 的方法用于检查用户是否已登录
//     const isAuthenticated = checkIfUserIsAuthenticated();
//     console.log('是否登录', isAuthenticated)
//     // 如果要访问的页面不是登录页面
//     if (to.path !== '/login') {
//         if (!isAuthenticated) {
//             console.log(isAuthenticated, 11111)
//             // 如果用户未登录且要访问的页面不是登录页面，则弹窗提示用户未登录，并在1秒后跳转到登录页面
//             alert('您还未登录，请先登录！');
//             next('/login');
//         } else {
//             // 如果用户已登录，则允许导航
//             next();
//         }
//     } else {
//         // 如果要访问的页面是登录页面
//         // 如果用户已经登录，则直接跳转到首页
//         if (isAuthenticated) {
//
//             next('/');
//         } else {
//             // 如果用户未登录，则允许导航到登录页面
//             next();
//         }
//     }
// });

// function checkIfUserIsAuthenticated() {
//     // 在这里实现检查用户是否已登录的逻辑
//     // 返回 true 表示用户已登录，返回 false 表示用户未登录
//     // 你可以根据具体情况从后端获取用户登录状态
//     // 这里简单地返回一个假值
//
//     return localStorage.getItem('jwtToken');
// }

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        // 从localStorage中清除JWT令牌或执行其他注销操作
        // console.log('要重新登录/呀')

        // 尝试重定向到登录页面

        router.push('/login')
            .then(() => {
                // console.log('重定向成功');
                localStorage.removeItem('jwtToken')
            })
            .catch();
    }

    return Promise.reject(error);
});


export default router;
// {
//     name: router,
//     created() {
//     document.title = 'aa'
//   }
// };
