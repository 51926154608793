import Vue from 'vue'
import App from './App.vue'
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";
// import config from './config';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(router);
// fetch.defaults = config.fetchDefaults
// console.log(config.fetchDefaults.headers.get('Authorization'),2223)
// Vue.prototype.$fetchDefaults = config.headers;
// Vue.prototype.$axios = config;

new Vue({
  router,
  render: h => h(App),
  // created() {
  //   document.title = localStorage.getItem('userName')
  // }
}).$mount('#app')
